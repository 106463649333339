@import "../../../styles/colors.scss";

html {
  scroll-behavior: smooth;
}

.header {
  display: flex;

  h1 {
    flex: 1;
    margin-left: 1rem;
    color: rgb(0, 0, 0, 0.77);
  }
}

h2 {
  color: $dark-gray;
  font-size: 1.75rem;
}

h3 {
  color: $black;
  font-size: 1.5rem;
}

li {
  padding-left: 0.5rem;
}

img {
  max-width: 100%;
}

.link-example {
  color: $dark-gray;
  overflow-wrap: break-word;
}

.query-params {
  margin: 1rem 0;
  color: $black;
}

.spacer-bottom {
  margin-bottom: 4rem;

  // General setup instructions section styles
  p,
  li {
    font-size: 1.125rem;
    line-height: 1.6;
  }

  ol > li {
    margin-bottom: 1rem;
  }

  // Nested lists should have original font size
  ol ol,
  ul ul {
    font-size: 0.889em; // This will bring nested lists back to normal size
  }
}
