@import "/src/styles/colors.scss";

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 20px;

  .empty-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > img {
      width: 100%;
      max-width: 400px;
      opacity: 0.4;
    }

    & > h2 {
      margin: 2em 0 0.5em 0;
      font-size: 24px;
      font-weight: 500;
      color: $black;
      text-align: center;
    }

    & > p {
      font-size: 18px;
      color: $dark-gray;
      text-align: center;
      margin: 0 0 1.5em 0;
    }   
  }
}