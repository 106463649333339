@import "/src/styles/colors.scss";

.box-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.tooltip-icon {
  position: absolute;
  font-size: 1.5rem;
  color: blue;
  padding-left: 3px;
  margin: -3px 0 0 0;
}

.input-wrapper {
  display: flex;
  flex-direction: column;

  input {
    margin: 5px 4px 4px 0;
  }

  .status-cell {
    margin-top: 5px;
  }
}

.url-span {
  word-break: break-word;
}

.card-info-icon {
  color: $secondary-blue;
}
